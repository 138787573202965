import React from "react";
import IconLogo from "../assets/img/icon-logo.png"
import { Link } from "react-router-dom";

const Welcome = () => {
    return(
        <>
            <div id="welcome_page">
                <div className="overlay"></div>
                <div className="row">
                    <div className="col-md-6">
                        <img src={IconLogo} alt="Company Logo"/>
                        <span>We're Still</span>
                        <h1>Cooking Our Website</h1>
                        <p>We are still to launch our website very soon. <br/>Stay Tuned.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Welcome;