import {createBrowserRouter, Route, createRoutesFromElements} from "react-router-dom";

import Welcome from "../pages/Welcome";
import Privacy from "../pages/Privacy";


  const router = createBrowserRouter(
    createRoutesFromElements([
      <Route key="welcome" path="/" element={<Welcome />} />,
      <Route key="privacy_policy" path="/privacy_policy" element={<Privacy/>} />,
    ])
  );
  
  export default router;