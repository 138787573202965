import React from "react";
import IconLogo from "../assets/img/icon-logo.png"

const Privacy = () => {
    return(
        <>
            <div id="privacy">
                <div className="header-part">
                    <img src={IconLogo} alt="company icon" />
                    <h4>Human <span>Capital</span> Finance</h4>
                </div>
                <h1>Privacy Policy for Loan Application System</h1>

                <p>This Privacy Policy governs the manner in which Human Capital Finance collects, uses, maintains, and discloses information collected from users (referred to as "Users") of the loan application system provided by Human Capital Finance.</p>

                <ol>
                    <li>Personal Information Collection</li>
                    <ol>
                        <li>1.1. Types of Information Collected</li>
                        <ul>
                            <p>We may collect the following types of personal information from Users:</p>
                            <li>Full name</li>
                            <li>Contact information (email address, phone number)</li>
                            <li>Identification information (ID number, passport number)</li>
                            <li>Employment information (company name, salary details)</li>
                            <li>Financial information (bank account details)</li>
                        </ul>

                        <li>1.2. Methods of Collection</li>
                        <ul>
                            <p>We collect personal information from Users through the following means:</p>
                            <li>Submission through the loan application form on our website or mobile application</li>
                            <li>Interaction via the WhatsApp Business API</li>
                            <li>Direct communication with our representatives via email or phone</li>
                            <li>Use of Personal Information</li>
                        </ul>
                    </ol>
                    
                    <li>Use of Personal Information</li>
                    <ol>
                        <li>2.1. Purpose of Collection</li>
                        <ul>
                            <p>We collect and use personal information for the following purposes:</p>
                            <li>Processing and evaluating loan applications</li>
                            <li>Verifying user identity and employment information</li>
                            <li>Contacting users regarding their loan applications</li>
                            <li>Complying with legal and regulatory requirements</li>
                            <li>Improving our loan application system and services</li>
                        </ul>
                        <li>2.2. Lawful Basis</li>
                        <ul>
                            <p>The processing of personal information is based on the user's consent or as necessary to perform a contract between the user and Human Capital Finance.</p>
                        </ul>
                    </ol>

                    <li>Protection of Personal Information</li>
                    <ol>
                        <li>3.1. Data Security Measures</li>
                        <ul>
                            <p>We implement appropriate data security measures to protect personal information from unauthorized access, alteration, disclosure, or destruction. These measures include:</p>
                            <li>Secure storage of personal information in encrypted databases</li>
                            <li>Limited access to personal information by authorized personnel only</li>
                            <li>Regular monitoring and testing of our security procedures</li>
                        </ul>
                        <li>3.2. Retention of Personal Information</li>
                        <ul>
                            <p>We retain personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law. When personal information is no longer required, we will securely delete or anonymize it.</p>
                        </ul>
                    </ol>
                    <li>Sharing of Personal Information</li>
                    <ol>
                        <li>4.1. Third-Party Service Providers</li>
                        <ul>
                            <p>We may share personal information with third-party service providers who assist us in operating our loan application system and providing related services. These service providers are contractually bound to keep personal information confidential and secure and are prohibited from using it for any other purpose.</p>
                        </ul>
                        <li>4.2. Legal Compliance</li>
                        <ul>
                            <p>We may disclose personal information to comply with applicable laws, regulations, legal processes, or enforceable government requests.</p>
                        </ul>
                    </ol>
                    <li>Rights and Choices</li>
                    <ol>
                        <li>5.1. Access and Correction</li>
                        <ul>
                            <p>Users have the right to access and correct their personal information. They can contact us using the information provided in this Privacy Policy to request access to or correction of their personal information.</p>
                        </ul>
                        <li>5.2. Withdrawal of Consent</li>
                        <ul>
                            <p>Users have the right to withdraw their consent for the processing of personal information at any time. However, withdrawing consent may result in the inability to access certain features or services provided by our loan application system.</p>
                        </ul>
                    </ol>
                    <li>POPIA Compliance</li>
                    <ul>
                        <p>In compliance with the Protection of Personal Information Act (POPIA) and other applicable data protection laws, we:</p>
                        <li>Only collect personal information necessary for the purposes outlined in this Privacy Policy</li>
                        <li>Take reasonable measures to ensure the accuracy and relevance of personal information</li>
                        <li>Implement appropriate technical and organizational measures to protect personal information</li>
                        <li>Obtain consent from users before processing their personal information, where required</li>
                        <li>Provide users with access to their personal information and the ability to correct or delete it</li>
                        <li>Only retain personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy</li>
                        <li>Notify users in the event of a data breach that may result in a high risk to their rights and freedoms</li>
                    </ul>

                    <li>Changes to this Privacy Policy</li>
                    <ul>
                        <p>We reserve the right to update this Privacy Policy at any time. Users are encouraged to check this page periodically for any changes. The date of the last update will be indicated at the top of this page.</p>
                    </ul>
                    <li>Contact Us</li>
                    <ul>If you have any questions or concerns about this Privacy Policy or the practices of our loan application system, please contact us at info@humancapitalfinance.co.za.</ul>
                </ol>
            </div>
        </>
    )
}

export default Privacy;